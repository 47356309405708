export default [
  {
    path: '/',
    redirect: () => {
      return 'home';
    },
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AppLayout" */ '../components/Layout/AppLayout.vue'
        ),
    },
    children: [
      {
        path: '/home',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () =>
            import(/* webpackChunkName: "Home" */ '../pages/Home.vue'),
        },
      },
      {
        path: '/notif-setting/:baId',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "Setting" */ '../pages/NotifSetting.vue'
            ),
        },
      },
      {
        path: '/top-up/:baId',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () =>
            import(/* webpackChunkName: "TopUp" */ '../pages/TopUp.vue'),
        },
      },
      {
        path: '/record/:baId',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () =>
            import(/* webpackChunkName: "Record" */ '../pages/Record.vue'),
        },
      },
      {
        path: '/top-up-result/:baId/:orderId',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TopUpResult" */ '../pages/TopUpResult.vue'
            ),
        },
      },
      {
        path: '/top-up-error/:baId/:orderId',
        meta: {
          requiresAuth: true,
          ddpayFailed: true,
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TopUpResult" */ '../pages/TopUpResult.vue'
            ),
        },
      },
      {
        path: '/:catchAll(.*)',
        components: {
          default: () =>
            import(/* webpackChunkName: "Error" */ '../pages/Error.vue'),
        },
      },
    ],
  },
];
