import { createStore } from 'vuex';
import ModuleLocale from './modules/locale';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import state from './state';

export default createStore({
  state,
  mutations,
  getters,
  actions,
  modules: {
    locale: ModuleLocale,
  },
});
