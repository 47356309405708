import { createI18n } from "vue-i18n";

function getLocale() {
  // 讀取cookie存入的當前語言
  const cookieLanguage = localStorage.getItem("locale");
  // 如果有返回當前語言
  if (cookieLanguage) {
    return cookieLanguage;
  }
  // 如果没有，獲取系統語言
  let language = navigator.language.toLowerCase();
  // 系統語言編碼和APP語言編碼不同時，如何轉換？如en-us/en-eg需統一轉成en
  if (language.startsWith("en-")) {
    language = "en";
  }
  // 獲取messages 語言，遍歷
  const locales = Object.keys(messages);
  for (const locale of locales) {
    // 如果message 包里面有系统语言返回
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  // 預設语言
  return "zh-tw";
}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

const messages = loadLocaleMessages();

export default createI18n({
  legacy: false,
  // locale: process.env.VUE_APP_I18N_LOCALE || 'zh-tw',
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "zh-tw",
  messages,
  // messages: loadLocaleMessages(),
});
