import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

function updateLanguage(setting, lang) {
  if (lang) {
    let language = lang == 'en' ? lang : 'zh-TW';
    localStorage.setItem('lang', language);
    setting.locale = language;
  } else {
    setting.locale = localStorage.getItem('lang');
  }
}

router.beforeEach((to, from, next) => {
  console.log(to, from);

  if (to.path === '/error') {
    return next();
  }

  store
    .dispatch('initPortal')
    .then(() => {
      updateLanguage(i18n, to.query.lang);

      let appId = store.state.portalInfo.sso?.appId;
      const sso = new window.qcsso();

      if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!appId) return console.error('no appId');

        sso.init({ appId }).then(() => {
          if (!sso.isLoggedIn()) {
            sso.login({
              redirectUrl: `${process.env.VUE_APP_HOST}${
                process.env.BASE_URL
              }${to.path.slice(1)}`,
            });
          } else {
            sso
              .getProfile()
              .then(function (user) {
                store.commit('updateUser', user);

                if (
                  store.state.billingAccounts.length === 0 ||
                  to.path === '/home'
                ) {
                  store
                    .dispatch('fetchBillingAccounts')
                    .then(() => {
                      return to.path == '/' ? next('/home') : next();
                    })
                    .catch(() => {
                      next('/error');
                    });
                } else {
                  return to.path == '/' ? next('/home') : next();
                }
              })
              .catch(function (e) {
                console.error(e);
                next('/error');
              });
          }
        });
      } else if (to.matched.some(record => record.meta.pwaCheck)) {
        // 不用登入但是如果登入就跳轉
        if (!appId) return console.error('no appId');

        sso.init({ appId }).then(() => {
          if (!sso.isLoggedIn()) {
            next();
          } else {
            next('/home');
          }
        });
      } else {
        next(); // make sure to always call next()!
      }
    })
    .catch(() => {
      next('/error');
    });
});

export default router;
