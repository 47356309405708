import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import VConsole from 'vconsole';

if (process.env.VUE_APP_ENV !== 'production') {
  new VConsole();
}

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import {
  faSliders,
  faCircle,
  faDollarSign,
  faCirclePlus,
  faAngleLeft,
  faCheck,
  faArrowUpRightFromSquare,
  faBell,
  faQuestion,
  faXmark,
  faArrowsRotate,
  faHourglassHalf,
  faCopy,
  faFileArrowDown,
  faHouse,
  faTriangleExclamation,
  faAngleRight,
  faExclamation,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircleQuestion,
  faCircleCheck,
  faCircleXmark,
  faFaceSadTear,
} from '@fortawesome/free-regular-svg-icons';
/* add icons to the library */
library.add(
  faSliders,
  faCircle,
  faDollarSign,
  faCirclePlus,
  faAngleLeft,
  faCheck,
  faArrowUpRightFromSquare,
  faBell,
  faCircleQuestion,
  faQuestion,
  faXmark,
  faArrowsRotate,
  faCircleCheck,
  faCircleXmark,
  faHourglassHalf,
  faCopy,
  faFileArrowDown,
  faFaceSadTear,
  faHouse,
  faTriangleExclamation,
  faAngleRight,
  faExclamation,
  faTrash
);

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
