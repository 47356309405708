// import axios from 'axios';

const apiHost = process.env.VUE_APP_API_HOST;
const portalKey = process.env.VUE_APP_PORTAL_KEY;
const sso = new window.qcsso();
const version = 'v1';

export function getBillingAccounts(merchantId, userId) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/members/${userId}/billing-accounts`,
  };
  return sso.request(config);
}

export function getDepositPlans(merchantId) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/deposit-plans`,
  };
  return sso.request(config);
}

export function getPortalInfo() {
  const config = {
    url: `${apiHost}/stores/${version}/portals/${portalKey}`,
    method: 'GET',
  };
  return sso.request(config);
}

export function getOrderInfo(merchantId, baId, orderId) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/billing-accounts/${baId}/records/${orderId}`,
  };
  return sso.request(config);
}

export function getBARecords(merchantId, baId, queryString) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/billing-accounts/${baId}/records`,
    params: queryString,
  };
  return sso.request(config);
}

export function getBAShops(merchantId, baId) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/billing-accounts/${baId}/shops`,
  };
  return sso.request(config);
}

export function getBATransactions(merchantId, baId, queryString) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/billing-accounts/${baId}/transactions`,
    params: queryString,
  };
  return sso.request(config);
}

export function getBALowAvailability(merchantId, baId) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/billing-accounts/${baId}/low-aval`,
  };
  return sso.request(config);
}

export function getBASubscribers(merchantId, baId) {
  const config = {
    method: 'get',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/billing-accounts/${baId}/subscribers`,
  };
  return sso.request(config);
}

export function exportTransactions(merchantId, baId, queryString) {
  const config = {
    method: 'get',
    responseType: 'blob',
    url: `${apiHost}/vas/${version}/merchants/${merchantId}/billing-accounts/${baId}/transactions/export`,
    params: queryString,
  };
  return sso.request(config);
}
