import { getPortalInfo, getBillingAccounts } from '@/apis/fetchData';

const actions = {
  initPortal({ state, commit }) {
    if (state.portalInfo) return;

    return getPortalInfo()
      .then(res => {
        console.log('portal setting: ', res.data);
        commit('updatePortalInfo', res.data);
      })
      .catch(err => {
        console.error('[Vuex Store] initPortal err:', err);
        throw err;
      });
  },

  fetchBillingAccounts({ state, commit }) {
    const { merchantId } = state.user.user._json;
    const { userId } = state.user;

    if (!merchantId) throw new Error('no merchantId');

    return getBillingAccounts(merchantId, userId)
      .then(res => {
        console.log('billing accounts: ', res.data);
        commit('updateBillingAccounts', res.data);
      })
      .catch(err => {
        console.error('[Vuex Store] fetchBillingAccounts err:', err);
        throw err;
      });
  },
};

export default actions;
