import i18n from "@/i18n";

const state = () => ({
  locale: "zh-tw", // 存放使用者選用的語系
  supportedLanguages: [
    {
      displayName: "繁中",
      code: "zh-tw",
    },
    {
      displayName: "En",
      code: "en",
    },
  ],
});

// getters
const getters = {
  getLocale: (state) => {
    return state.locale;
  },
  getSupportedLanguages: (state) => {
    return state.supportedLanguages;
  },
};

// mutations
const mutations = {
  setLocale(state, { language }) {
    state.locale = language;
    localStorage.setItem("locale", language);
    i18n.global.locale = language;
  },
};

// actions
const actions = {};

const language = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export default language;
