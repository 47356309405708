const mutations = {
  updateUser(state, value) {
    state.user = value;
  },
  updateBillingAccounts(state, value) {
    state.billingAccounts = value;
  },
  updatePortalInfo(state, value) {
    state.portalInfo = value;
  },
};

export default mutations;
