<template>
  <router-view />
</template>

<script>
window.addEventListener(
  'resize',
  (function () {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
  })()
);

export default {
  name: 'App',
  setup() {},
};
</script>
